@import "~antd/dist/antd.less";
@import "./AppVariables.less";

@font-face {
  font-family: "Suisse";
  src: url("assets/fonts/SuisseIntl-Bold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "Suisse";
  src: url("assets/fonts/SuisseIntl-Medium.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Suisse";
  src: url("assets/fonts/SuisseIntl-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Suisse";
  src: url("assets/fonts/SuisseIntl-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Suisse";
  src: url("assets/fonts/SuisseIntl-Regular.otf") format("opentype");
  font-weight: 300;
}

@font-family: Suisse, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
@font-variant-base: normal;
@font-feature-settings-base: normal;
@heading-color: @blue-100;
@text-color: @blue-100;
@text-color-secondary: @grey-70;
@font-size-base: 15px;
@font-size-sm: 13px;
@error-color: @red-50;
@heading-1-size: 42px;
@heading-2-size: 36px;
@heading-3-size: 26px;
@heading-4-size: 17px;
@typography-title-margin-bottom: 0px;
@link-color: @blue-70;

// Tag
@tag-font-size: @font-size-base;
@tag-line-height: 21.5px;

//Table
@table-header-color: @grey-70; // text color
@table-padding-vertical: 13px;
@table-selected-row-bg: white;
@table-header-cell-split-color: inherit;

// Checkbox
@checkbox-color: @blue-70;

//Dropdown
@dropdown-vertical-padding: 7px;
@dropdown-edge-child-vertical-padding: 6px;

//Input
@input-border-color: @grey_4;
@input-bg: #fbfbff;
@input-color: @blue-100;
@input-placeholder-color: @grey-70;
@input-hover-border-color: @blue-70;

//Select
@select-border-color: @grey_4;
@select-background: #fbfbff;
@select-item-selected-bg: #f3f4ff;

//Radio
@radio-button-bg: #fbfbff;
@radio-dot-color: #091794;
@radio-button-hover-color: @radio-dot-color;
@radio-button-active-color: @radio-dot-color;

// The background colors for active and hover states for things like
// list items or table cells.

//Modal
@modal-mask-bg: fade(@grey_100, 85%);

@item-hover-bg: #f6faff;

.@{popover-prefix-cls} {
  &-inner {
    border-radius: 20px;
    width: 300px;
  }
  &-title {
    padding: 1rem;
  }
}

.ant-carousel {
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  height: 100%;
}

.splashscreen {
  background: @blue-100;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100vh;
  width: 100%;

  .text {
    position: relative;
    width: max-content;
  }

  .word {
    position: absolute;
    left: 65px;
    min-width: max-content;
    opacity: 0;
  }

  .letter {
    display: inline-block;
    position: relative;
    transform: translateZ(25px);
    transform-origin: 50% 50% 25px;
    color: #fc9e08;
  }

  .letter.out {
    transform: rotateX(90deg);
    transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .letter.behind {
    transform: rotateX(-90deg);
  }

  .letter.in {
    transform: rotateX(0deg);
    transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}

.ant-popover {
  border-radius: 20px;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.disabled{
  cursor: no-drop !important
}