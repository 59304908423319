
:root{
  --pink:#E95486;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

.intercom-lightweight-app-launcher {
  bottom: 15px !important;
}

@media screen and (max-width: 480px) {
  /* Intercom alignment on mobile devices */
  .intercom-lightweight-app-launcher {
    position: fixed;
    left: 10px;
  }
}
