@import "../../AppVariables.less";

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}

.ant-form-item-has-error {
  input {
    &:focus ~ label span {
      color: @red-50 !important;
      display: inline-block;
    }

    &:not(:focus):not([value=""]) ~ label span {
      color: @red-50 !important;
      display: inline-block;
      animation: shake 0.52s cubic-bezier(0.36, 0.07, 0.19, 0.97);
      transform: translate3d(0, 0, 0);
    }
  }
}
